import { MembershipApi, OrdersOrderType } from '@wix/ambassador-membership-api/http';
import { PricingPlansReadApi, ListPublicPlansRequest, PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';

type PricingPlansReadApi = ReturnType<typeof PricingPlansReadApi>;
type PlansService = ReturnType<PricingPlansReadApi['PlansService']>;

export type MembershipApi = ReturnType<typeof MembershipApi>;
export type OrdersService = ReturnType<MembershipApi['OrdersService']>;
export type PremiumService = ReturnType<MembershipApi['PremiumService']>;

export const createMembershipApi = (baseUrl: string) => MembershipApi(baseUrl + '/_api/paid-plans');

export const createPricingPlansReadApi = (baseUrl: string) => PricingPlansReadApi(baseUrl + '/_api/paid-plans');

export class PurchaseLimitExceededError extends Error {
  constructor() {
    super('Purchase limit exceeded.');
    Object.setPrototypeOf(this, PurchaseLimitExceededError.prototype);
  }
}

export class ShouldUpgradeError extends Error {
  constructor() {
    super('Should upgrade.');
    Object.setPrototypeOf(this, ShouldUpgradeError.prototype);
  }
}

export class PricingPlansApi {
  protected headers: Record<string, string> = {
    Authorization: '',
    'Content-Type': 'application/json',
  };
  protected plansService: PlansService;
  protected ordersService: OrdersService;
  protected premiumService: PremiumService;

  constructor(headers: Record<string, string>, membershipApi: MembershipApi, pricingPlansReadApi: PricingPlansReadApi) {
    this.headers = { ...this.headers, ...headers };
    this.plansService = pricingPlansReadApi.PlansService();
    this.ordersService = membershipApi.OrdersService();
    this.premiumService = membershipApi.PremiumService();
  }

  public updateInstance(newInstance: string) {
    this.headers.Authorization = newInstance;
  }

  public async loadPaidPlans(request: ListPublicPlansRequest = {}) {
    const { plans } = await this.plansService(this.headers).listPublicPlans(request);
    return plans || [];
  }

  public async createOrder(plan: PublicPlan, memberId: string) {
    const ordersService = this.ordersService(this.headers);

    if (plan.paymentOptions?.price?.amount !== '0') {
      const { acceptPaymentsEnabled, acceptPaymentsRequired } = await this.getPremiumStatus();
      if (acceptPaymentsRequired && !acceptPaymentsEnabled) {
        throw new ShouldUpgradeError();
      }
    }

    const { purchaseLimitExceeded } = await ordersService.getPurchasePreview({
      memberId,
      planId: plan.id,
      validFrom: new Date().toISOString(),
      orderType: OrdersOrderType.ONLINE,
    });
    if (purchaseLimitExceeded) {
      throw new PurchaseLimitExceededError();
    }

    const { cashierOrderId: snapshotId, orderId } = await ordersService.purchasePlan({ planId: plan.id });
    return { orderId, snapshotId };
  }

  public getPremiumStatus() {
    return this.premiumService(this.headers).getPremiumStatus({});
  }

  public async getPremiumMessageDisplayStatus() {
    const res = await this.getPremiumStatus();
    return !res?.acceptPaymentsEnabled && !res?.hasOldPlans;
  }
}
